import React, { useState, useEffect } from "react";
import { signout } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { VscUnverified } from "react-icons/vsc";
import { MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { getAllFranchise,getPopularFranchiseById } from "../../redux/franchiseSlice";

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
// import { XMarkIcon } from '@heroicons/react/20/solid'

import {
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Notifications from "../Home/Notifications";
const navigation = [
  { name: "Home", to: "/" },
  {
    name: "Franchise Directory",
    subMenu: [
      { name: "Automotive", to: "/franchise_dir?category=Automotive" },
      {
        name: "Business Services",
        to: "/franchise_dir?category=Business Services",
      },
      {
        name: "Dealers & Distributors",
        to: "/franchise_dir?category=Dealers & Distributors",
      },
      { name: "Education", to: "/franchise_dir?category=Education" },
      { name: "Fashion", to: "/franchise_dir?category=Fashion" },
      {
        name: "Food And Beverage",
        to: "/franchise_dir?category=Food And Beverage",
      },
      {
        name: "Home Based Business",
        to: "/franchise_dir?category=Home Based Business",
      },
      {
        name: "Hotel, Travel & Tourism",
        to: "/franchise_dir?category=Hotel, Travel & Tourism",
      },
      { name: "Retail", to: "/franchise_dir?category=Retail" },
      {
        name: "Sports, Fitness & Entertainment",
        to: "/franchise_dir?category=Sports, Fitness & Entertainment",
      },
      {
        name: "Beauty & Health",
        to: "/franchise_dir?category=Beauty & Health",
      },
      {
        name: "Pharmacy",
        to: "/franchise_dir?category=Pharmacy",
      },
    ],
  },
  {
    name: "Useful ",
    subMenu: [
      { name: "About", to: "/aboutfranchise" },
      { name: "Contact US", to: "/contactus" },
      { name: "Privacy Policy", to: "/privacypolicy" },
      { name: "Terms of Use", to: "/Term" },
      // { name: "Cookie Policy", to: "/CookiesPolicy" },
      { name: "Career", to: "/Career" },
      // { name: "Franchise Disclosure Document (FDD)", to: "Franchiseisclosure " },
    ],
  },
  { name: "Advertise", to: "/advertise" },
  { name: "Franchise Development", to: "/franchisedevelopment" },
  { name: "Popular Searches", to: "/popularSearch" },
];

export default function  Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const { allfranchise } = useSelector((state) => state.franchise);
  const [ipAddress, setIpAddress] = useState('');
  const [activeTab, setActiveTab] = useState(navigation[0].name);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [AllFranchise, setAllFranchise] = useState("");

  function handlesignout() {
    dispatch(signout());
  }
  useEffect(() => {
    dispatch(getAllFranchise());
  }, [dispatch]);

  useEffect(() => {
    setAllFranchise(
      allfranchise?.filter((item) =>
        item?.franchise_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery]);

  function handlePopularSearch (person){
    // console.log(person)
    const _id=person?._id
    dispatch(getPopularFranchiseById(_id))
    navigate(`/franchisedetail/${person.franchise_name.replace(" ","_")}/${person?._id}`)
  }

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip); // Set the IP address in state
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  return (
    <>
      <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <div
          aria-hidden="true"
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        >
          <div
            style={{
              clipPath:
                "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
            }}
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          />
        </div>
        <div
          aria-hidden="true"
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
        >
          <div
            style={{
              clipPath:
                "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
            }}
            className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          />
        </div>

        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          <p className="text-[12px] leading-6 text-gray-800">
            <strong className="font-semibold">Ready to Start?</strong>
            <svg
              viewBox="0 0 2 2"
              aria-hidden="true"
              className="mx-2 inline h-0.5 w-0.5 fill-current"
            >
              <circle r={1} cx={1} cy={1} />
            </svg>
            List your brand or find your ideal franchise opportunity now!
          </p>
          {auth == null ? (
            <>
              <Link
                to="/user/registration"
                className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-[12px] font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                Register now <span aria-hidden="true">&rarr;</span>
              </Link>
              <Link
                to="/login"
                className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-[12px] font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
              >
                Sign in <span aria-hidden="true">&rarr;</span>
              </Link>
            </>
          ) : (
            <Link
              to="/user/profile"
              className="flex-none capitalize rounded-full bg-gray-900 px-3.5 py-1 text-[12px] font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              <div className="flex items-center justify-around gap-3">
                <UserIcon className="h-6" />
                {auth?.username || auth?.companyName}
                <span aria-hidden="true"> &rarr;</span>
              </div>
            </Link>
          )}
        </div>
        <div className="flex flex-1 justify-end">
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon aria-hidden="true" className="h-5 w-5 text-gray-100" />
          </button>
        </div>
      </div>

      <Disclosure as="header" className="bg-gray-800 text-white shadow-lg ">
        <div className="mx-auto max-w-full lg:px-8 lg:divide-y px-3  lg:divide-gray-700 ">
          <div className="relative flex h-16 justify-between">
            <div className="relative z-50 flex px-2 lg:px-0">
              <div className="flex flex-shrink-0 cursor-pointer items-center">
                <Link to="/">
                  <img
                    alt="franchise logo"
                    src="/logo.png"
                    className="h-6 w-auto"
                  />
                </Link>
              </div>
            </div>
            <div className="relative z-40 hidden lg:flex  flex-1 items-center justify-center px-2 sm:absolute sm:inset-0">
              <div className=" sm:max-w-64 lg:max-w-lg  ">
                <div className="flex gap-4 items-center px-6 py-4 md:mx-auto md:max-w-3xl lg:mx-0 lg:max-w-none xl:px-0">
                  <div className="relative z-50">
                    <input
                      type="text"
                      className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search . . ."
                    />
                    {searchQuery &&
                      AllFranchise?.length > 0 && (
                        <div className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {AllFranchise?.map((person, index) => (
                            <div
                              key={index}
                              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white" // Added hover effects
                            >
                              <div
                               
                                onClick={()=>handlePopularSearch(person)}
                                className="flex items-center w-full"
                              >
                                <img
                                  src={
                                    person?.companyLogo
                                      ? `/uploads/InvestorData/${person?.companyLogo}`
                                      : "/defaultlogo.png"
                                  }
                                  alt=""
                                  className="h-6 w-6 flex-shrink-0 rounded-full"
                                />
                                <span className="ml-3 truncate group-data-[selected]:font-semibold">
                                  {person?.franchise_name}
                                </span>
                                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                                  {person?.isVerified === "yes" ? (
                                    <MdVerified className="h-5 w-5 text-green-500" />
                                  ) : (
                                    <VscUnverified className="h-5 w-5 text-red-500" />
                                  )}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>

        

                  <div className="hidden lg:flex lg:flex-1 language h-12 lg:justify-end">
                    <div  id="google_translate_element" className="block w-auto  text-[12px]  font-medium transition duration-75    text-gray-800 ">
                   <option hidden>Language</option>
                       {/*   <option value="English">EN - English</option>
                      <option value="Hindi">HI - Hindi</option> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative z-10 flex items-center lg:hidden">
              {/* Mobile menu button */}
              <DisclosureButton className="group  relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open menu</span>
                <Bars3Icon
                  aria-hidden="true"
                  className="block h-6 w-6 group-data-[open]:hidden"
                />
                <XMarkIcon
                  aria-hidden="true"
                  className="hidden h-6 w-6 group-data-[open]:block"
                />
              </DisclosureButton>
            </div>
            <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex gap-6 lg:items-center lg:max-w-lg">
              {auth?.role == "investor" && (
                <div className="relative inline-block">
                  <button
                    onClick={() => setOpen(!open)}
                    className="relative z-10 block p-[9px] text-gray-700 bg-white border border-transparent rounded-full text-gray-800 focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring  focus:outline-none"
                  >
                    <svg
                      className="w-5 h-5 text-gray-800 "
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22ZM20 19H4V17L6 16V10.5C6 7.038 7.421 4.793 10 4.18V2H13C12.3479 2.86394 11.9967 3.91762 12 5C12 5.25138 12.0187 5.50241 12.056 5.751H12C10.7799 5.67197 9.60301 6.21765 8.875 7.2C8.25255 8.18456 7.94714 9.33638 8 10.5V17H16V10.5C16 10.289 15.993 10.086 15.979 9.9C16.6405 10.0366 17.3226 10.039 17.985 9.907C17.996 10.118 18 10.319 18 10.507V16L20 17V19ZM17 8C16.3958 8.00073 15.8055 7.81839 15.307 7.477C14.1288 6.67158 13.6811 5.14761 14.2365 3.8329C14.7919 2.5182 16.1966 1.77678 17.5954 2.06004C18.9942 2.34329 19.9998 3.5728 20 5C20 6.65685 18.6569 8 17 8Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              )}
              <div className="flex items-center gap-x-3 ">
                <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full">
                  <PhoneIcon className="w-7 h-6 text-gray-800 " />
                </div>
                <div className="flex flex-col items-start text-gray-100 ">
                  <span className="font-medium text-[12px]">Need Help?</span>
                  <span className="text-[12px]">+91 80535-30300</span>
                </div>
              </div>
            </div>
          </div>

          <nav
            aria-label="Global"
            className="hidden lg:flex lg:space-x-8 lg:py-2 lg:items-center justify-between"
          >
            <div className="hidden lg:flex lg:items-center gap-3">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  onClick={() => setActiveTab(item.name)} // Set active tab on click
                  className={`text-[12px] leading-6 px-3 py-1.5 rounded-md transition-colors duration-200
            ${
              activeTab === item.name
                ? "bg-black text-white"
                : "text-gray-100 hover:text-gray-100 "
            }
          `}
                >
                  {item?.subMenu && (
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="text-[12px] inline-flex w-full justify-center gap-x-1.5 rounded-md px-2   text-gray-100  transition-colors duration-200">
                          {item.name}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-4 w-4 mt-[5px] text-gray-200"
                          />
                        </MenuButton>
                      </div>
                      <MenuItems className="absolute -left-[10px] z-50 mt-3 min-w-48 origin-top-right rounded-sm border border-gray-300 bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 transition-transform focus:outline-none">
                        <div className="py-1">
                          {item.subMenu.map((i, n) => (
                            <MenuItem>
                              <Link
                                to={i.to}
                                className="block px-4 py-2 text-[13px] forn-semibold text-gray-800 hover:bg-gray-100 hover:text-gray-900  capitalize "
                              >
                                {i.name}
                              </Link>
                            </MenuItem>
                          ))}
                        </div>
                      </MenuItems>
                    </Menu>
                  )}

                  {!item.subMenu && item.name}
                </Link>
              ))}
            </div>
            <div>
              {auth?.role == "user" || auth?.role == "investor" ? (
                <button
                  className="flex-none capitalize rounded-full bg-gray-900 px-3.5 py-1 text-[12px] font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                  onClick={handlesignout}
                >
                  Logout
                </button>
              ) : (
                <Link to="/investor/registration">
                  <button className="flex-none capitalize rounded-md  text-[13px] bg-gray-300 px-5 py-2.5  font-semibold text-gray-800 hover:text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
                    List Your Brand
                  </button>
                </Link>
              )}
            </div>
          </nav>
        </div>

        <DisclosurePanel as="nav" aria-label="Global" className="lg:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.to}
                onClick={() => setActiveTab(item.name)}
                className={`block text-[12px] leading-6 px-3 py-1.5 rounded-md transition-colors duration-200
            ${
              activeTab === item.name
                ? "bg-black max-w-40 text-white"
                : "text-gray-100 hover:text-gray-100 "
            }
          `}
              >
                {item?.subMenu && (
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <MenuButton className="text-[12px] inline-flex w-full justify-center gap-x-1.5 rounded-md px-2   text-gray-100  transition-colors duration-200">
                        {item.name}
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="-mr-1 h-4 w-4 mt-[5px] text-gray-200"
                        />
                      </MenuButton>
                    </div>
                    <MenuItems className="absolute -left-[10px] z-10 mt-3 min-w-48 origin-top-right rounded-sm border border-gray-300 bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 transition-transform focus:outline-none">
                      <div className="py-1">
                        {item.subMenu.map((i, n) => (
                          <MenuItem>
                            <Link
                              to={i.to}
                              className="block px-4 py-2 text-[13px] forn-semibold text-gray-800 hover:bg-gray-100 hover:text-gray-900  capitalize "
                            >
                              {i.name}
                            </Link>
                          </MenuItem>
                        ))}
                      </div>
                    </MenuItems>
                  </Menu>
                )}

                {!item.subMenu && item.name}
              </Link>
            ))}
            <Menu as="div" className="relative  inline-block text-left mx-3 ">
              <div>
                <MenuButton className="text-[12px] py-1 px-4 border-2 inline-flex w-full justify-center gap-x-1.5 rounded-md  text-gray-100  transition-colors duration-200">
                  Investor
                  <ChevronDownIcon
                    aria-hidden="true"
                    className="-mr-1 h-5 w-5 text-gray-400"
                  />
                </MenuButton>
              </div>
              <MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition-transform focus:outline-none">
                <div className="py-1">
                  <MenuItem>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-[12px] text-gray-100 hover:bg-gray-100 hover:text-gray-100"
                    >
                      IPO
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-[12px] text-gray-100 hover:bg-gray-100 hover:text-gray-100"
                    >
                      Policies
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Link
                      to="#"
                      className="block px-4 py-2 text-[12px]  text-gray-100 hover:bg-gray-100 hover:text-gray-100"
                    >
                      Corporate Governance
                    </Link>
                  </MenuItem>
                </div>
              </MenuItems>
            </Menu>
          </div>
          <div className="border-t border-gray-700 pb-3 pt-4">
            <div className="flex items-center px-4 justify-between">
              <div>
                {auth?.role == "user" || auth?.role == "investor" ? (
                  <button
                    className="flex-none capitalize rounded-lg bg-black border px-5 py-1.5 text-[12px] font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    onClick={handlesignout}
                  >
                    Logout
                  </button>
                ) : (
                  <Link to="/investor/registration">
                    <button className="flex-none capitalize rounded-md  text-[13px] bg-gray-300 px-5 py-2.5  font-semibold text-gray-900 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900">
                      List Your Brand
                    </button>
                  </Link>
                )}
              </div>
              <div className="flex items-center ">
                {auth?.role == "investor" && (
                  <div className="relative inline-block">
                    <button
                      onClick={() => setOpen(!open)}
                      className="relative z-10 block p-[9px] text-gray-700 bg-white border border-transparent rounded-full  focus:border-blue-500 focus:ring-opacity-40 dark:focus:ring-opacity-40 focus:ring-blue-300 dark:focus:ring-blue-400 focus:ring  focus:outline-none"
                    >
                      <svg
                        className="w-5 h-5 text-gray-800 "
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C10.8954 22 10 21.1046 10 20H14C14 21.1046 13.1046 22 12 22ZM20 19H4V17L6 16V10.5C6 7.038 7.421 4.793 10 4.18V2H13C12.3479 2.86394 11.9967 3.91762 12 5C12 5.25138 12.0187 5.50241 12.056 5.751H12C10.7799 5.67197 9.60301 6.21765 8.875 7.2C8.25255 8.18456 7.94714 9.33638 8 10.5V17H16V10.5C16 10.289 15.993 10.086 15.979 9.9C16.6405 10.0366 17.3226 10.039 17.985 9.907C17.996 10.118 18 10.319 18 10.507V16L20 17V19ZM17 8C16.3958 8.00073 15.8055 7.81839 15.307 7.477C14.1288 6.67158 13.6811 5.14761 14.2365 3.8329C14.7919 2.5182 16.1966 1.77678 17.5954 2.06004C18.9942 2.34329 19.9998 3.5728 20 5C20 6.65685 18.6569 8 17 8Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                )}
                <div className="flex items-center justify-center w-10 h-10 mx-2 rounded-full">
                  <PhoneIcon className="w-6 h-6 text-gray-100" />
                </div>
                <div className="flex flex-col items-start text-gray-100">
                  <span className="font-medium text-[12px]">Need Help?</span>
                  <span className="text-[12px]">+001 123 456 789</span>
                </div>
              </div>
            </div>
            <div className="px-4 my-4 flex justify-between items-center">
              <button className="border bg-black block text-white px-5 py-1.5 rounded-md text-[12px]">
                {" "}
                <Link to="/registration">Register</Link>
              </button>
              <button className="border  bg-black block text-white px-5 py-1.5 rounded-md text-[12px]">
                <Link to="/login">Sign in</Link>
              </button>
            </div>
          </div>
        </DisclosurePanel>
        {/* <main>
          <Outlet />
        </main> */}
      </Disclosure>
      {open && <Notifications open={open} setOpen={() => setOpen(false)} />}
    </>
  );
}
